<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="openAddcontactModal"
      v-if="
        !hideDetails && $can({ key: 'clients', expectedPermission: 'edit' })
      "
    >
      <icon type="plus" /> Add Contact
    </button>

    <modal
      class="update-contact-modal"
      name="update-contact-modal"
      transition="pop-out"
      :height="500"
      :width="600"
    >
      <div class="position-relative modal-outer-container">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeUpdateContactModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="modal-content-container">
          <div class="card-body">
            <form
              @submit.prevent="
                updateContactMiddle({
                  patientId: patient.id,
                  contact: selectedContact,
                })
              "
              validate
            >
              <input-control
                v-model="selectedContact.name"
                :required="true"
                :disabled="viewOnly"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                type="horizontal"
                placeholder="Last, First"
                >Name</input-control
              >

              <input-control
                v-model="selectedContact.relationship"
                :required="true"
                :disabled="viewOnly"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                type="horizontal"
                >Relationship</input-control
              >

              <input-control
                v-model="selectedContact.phone"
                :required="true"
                :disabled="viewOnly"
                :cleave="cleave.phone"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                type="horizontal"
                >Phone</input-control
              >

              <input-control
                v-model="selectedContact.email"
                :required="true"
                :disabled="viewOnly"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                type="horizontal"
                >Email</input-control
              >

              <input-control
                v-model="selectedContact.address"
                :required="true"
                :disabled="viewOnly"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                type="horizontal"
                >Address</input-control
              >

              <div class="form-group row align-items-center">
                <div class="col-md-3">
                  <label class="">Medical Decisions</label>
                </div>
                <div class="col-md-9 row mx-0">
                  <div class="custom-control custom-radio mr-4">
                    <input
                      type="radio"
                      id="yes"
                      class="custom-control-input"
                      value="1"
                      :disabled="viewOnly"
                      v-model="selectedContact.medical_decisions"
                    />
                    <label class="custom-control-label" for="yes">Yes</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="no"
                      class="custom-control-input"
                      value="0"
                      :disabled="viewOnly"
                      v-model="selectedContact.medical_decisions"
                    />
                    <label class="custom-control-label" for="no">No</label>
                  </div>
                </div>
              </div>

              <save :saving="isSaving" v-if="!viewOnly">Update Contact</save>
            </form>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="delete-contact-modal"
      name="delete-contact-modal"
      transition="pop-out"
      :height="210"
      :width="550"
    >
      <div class="position-relative">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeDeleteContactModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div>
          <div class="card-body text-center">
            <h3>
              Are you sure you want to delete contact "
              {{ selectedContact.name }} " ?
            </h3>

            <div class="row">
              <div
                class="col-md-8"
                style="margin: 25px auto; text-align: center"
              >
                <button
                  class="
                    btn btn-danger
                    col-md-4
                    mr-5
                    contact-confirm-delete-btns
                  "
                  @click="
                    deleteSelectedContactMiddle({
                      patientId: patient.id,
                      contactId: selectedContact.id,
                    })
                  "
                >
                  <span
                    v-if="isDeleting"
                    class="fa fa-spin fa-circle-notch"
                  ></span>
                  <span v-if="!isDeleting"> Yes </span>
                </button>
                <button
                  class="btn btn-theme col-md-4"
                  @click="closeDeleteContactModal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="add-contact-modal"
      name="add-contact-modal"
      transition="pop-out"
      :height="500"
      :width="600"
    >
      <div class="position-relative modal-outer-container">
        <div class="vm--top-right-slot" style="z-index: 1">
          <button class="btn p-2 border-0" @click="closeAddContactModal">
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="modal-content-container">
          <div class="card-body">
            <form
              @submit.prevent="
                addNewContactMiddle({ patientId: patient.id, newContact })
              "
              validate
            >
              <input-control
                v-model="newContact.name"
                :required="true"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                type="horizontal"
                placeholder="Last, First"
                >Name</input-control
              >

              <input-control
                v-model="newContact.relationship"
                :required="true"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                type="horizontal"
                >Relationship</input-control
              >

              <input-control
                v-model="newContact.phone"
                :required="true"
                :cleave="cleave.phone"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                type="horizontal"
                >Phone</input-control
              >

              <input-control
                v-model="newContact.email"
                :required="true"
                type="horizontal"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                control="email"
                >Email</input-control
              >

              <input-control
                v-model="newContact.address"
                :required="true"
                labelClass="col-md-3"
                wrapClass="align-items-center"
                type="horizontal"
                >Address</input-control
              >

              <div class="form-group row align-items-center">
                <div class="col-md-3">
                  <label class="">Medical Decisions</label>
                </div>

                <div class="col-md-9 row mx-0">
                  <div class="custom-control custom-radio mr-4">
                    <input
                      type="radio"
                      id="yes"
                      class="custom-control-input"
                      value="1"
                      v-model="newContact.medical_decisions"
                    />
                    <label class="custom-control-label" for="yes">Yes</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="no"
                      class="custom-control-input"
                      value="0"
                      v-model="newContact.medical_decisions"
                    />
                    <label class="custom-control-label" for="no">No</label>
                  </div>
                </div>
              </div>
              <save :saving="isSaving" classes="save-button-60 btn btn-theme"
                >Add Contact</save
              >
            </form>
          </div>
        </div>
      </div>
    </modal>

    <alert class="my-4 light-shadow" v-if="contacts.isLoading" />
    <div>
      <alert
        class="my-4 light-shadow"
        v-if="!contacts.isLoading && (!contacts.data || !contacts.data.length)"
        >No Contacts</alert
      >
    </div>
    <div
      class="card shadow-card overflow-auto mobile-tablet-no-card border-0"
      v-if="!contacts.isLoading && contacts.data && contacts.data.length"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Relationship</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Address</th>
            <th>Medical Decisions</th>
            <th
              v-if="
                !hideDetails &&
                $can({ key: 'clients', expectedPermission: 'edit' })
              "
              class="text-center"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="contact in contacts.data" :key="contact.id">
            <td v-if="!hideDetails">
              <b class="mobile-tablet-only mr-2">Name:</b>
              <button
                class="btn pt-2 pb-2 pl-0 border-0 btn-link break-word"
                @click="openUpdateContactModal(contact, true)"
              >
                {{ contact.name }}
              </button>
            </td>
            <td v-if="hideDetails">
              <b class="mobile-tablet-only mr-2">Name:</b>{{ contact.name }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Relationship:</b
              >{{ contact.relationship }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Phone:</b>{{ contact.phone }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Email:</b
              ><a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Address:</b
              >{{ contact.address }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Medical Decisions:</b>
              {{ contact.medical_decisions == "1" ? "Yes" : "No" }}
            </td>
            <td
              v-if="
                !hideDetails &&
                $can({ key: 'clients', expectedPermission: 'edit' })
              "
              class="text-center td-actions"
            >
              <button
                class="btn btn-sm btn-black border-0 mr-2"
                @click="openUpdateContactModal(contact)"
              >
                <b class="mobile-tablet-only mr-2">Update</b>
                <i class="fa fa-edit"></i>
              </button>
              <button
                class="btn btn-sm btn-danger border-0"
                @click="openDeleteContactModal(contact)"
              >
                <b class="mobile-tablet-only mr-2">Delete</b>
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "patient.contacts",
  props: ["patientId", "hideDetails"],
  data() {
    return {
      isSaving: false,
      isDeleting: false,
      viewOnly: false,
      newContact: {
        patient_id: this.patientId,
        name: "",
        relationship: "",
        phone: "",
        email: "",
        address: "",
        medical_decisions: "0",
      },
      selectedContact: {
        patient_id: "",
        id: "",
        name: "",
        relationship: "",
        phone: "",
        email: "",
        address: "",
        medical_decisions: "0",
      },
      cleave: {
        phone: { blocks: [3, 3, 4], delimiter: "-", numericOnly: true },
      },
    };
  },
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
      contacts: (state) => state.patients.contacts,
    }),
  },
  mounted() {
    this.getRelatedContacts(this.patientId || this.patient.id);
  },
  methods: {
    ...mapActions({
      getRelatedContacts: "patients/contacts/get",
      connect: "patients/contacts/connect",
      deleteSelectedContact: "patients/contacts/delete",
      updateSelectedContact: "patients/contacts/update",
    }),
    checkEmail: function (email) {
      const emailDotIndex = (email || "").lastIndexOf(".");
      if (emailDotIndex <= (email || "").indexOf("@") + 1) {
        return false;
      }
      return true;
    },
    clearData() {
      this.newContact = {
        patient_id: "",
        name: "",
        relationship: "",
        phone: "",
        email: "",
        address: "",
        medical_decisions: "0",
      };
    },
    updateContactMiddle(objecToSend) {
      if (!this.checkEmail(objecToSend.contact.email)) {
        return Swal.fire({
          title: "Validation Error",
          text: "Invalid email address",
          icon: "error",
        });
      }
      let vm = this;
      vm.isSaving = true;
      this.updateSelectedContact(objecToSend).then(function () {
        vm.getRelatedContacts(vm.patientId || vm.patient.id);
        vm.closeUpdateContactModal();
        vm.isSaving = false;
      });
    },
    addNewContactMiddle(objecToSend) {
      let vm = this;
      objecToSend.patientId = this.patientId || this.patient.id;
      objecToSend.newContact.patient_id = objecToSend.patientId;
      if (!this.checkEmail(objecToSend.newContact.email)) {
        return Swal.fire({
          title: "Validation Error",
          text: "Invalid email address",
          icon: "error",
        });
      }
      this.isSaving = true;
      this.connect(objecToSend).then(function () {
        vm.clearData();
        vm.getRelatedContacts(vm.patientId || vm.patient.id);
        vm.closeAddContactModal();
        vm.isSaving = false;
      });
    },
    deleteSelectedContactMiddle(objecToSend) {
      let vm = this;
      vm.isDeleting = true;
      this.deleteSelectedContact(objecToSend).then(function () {
        vm.getRelatedContacts(vm.patientId || vm.patient.id);
        vm.closeDeleteContactModal();
        vm.isDeleting = false;
      });
    },
    openAddcontactModal() {
      this.$modal.show("add-contact-modal");
    },
    openDeleteContactModal(selectedContact) {
      this.selectedContact = JSON.parse(JSON.stringify(selectedContact));
      this.$modal.show("delete-contact-modal");
    },
    openUpdateContactModal(selectedContact, viewOnly) {
      this.selectedContact = JSON.parse(JSON.stringify(selectedContact));
      this.viewOnly = viewOnly;
      this.$modal.show("update-contact-modal");
    },
    closeAddContactModal() {
      this.$modal.hide("add-contact-modal");
    },
    closeDeleteContactModal() {
      this.$modal.hide("delete-contact-modal");
    },
    closeUpdateContactModal() {
      this.$modal.hide("update-contact-modal");
    },
  },
};
</script>

<style lang="scss">
.add-contact-modal,
.update-contact-modal {
  .modal-outer-container {
    height: 100%;
    .modal-content-container {
      height: calc(100% - 35px);
      overflow: auto;
    }
  }
}
</style>
